import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { getCommonInitOptions } from './sentry';
import {
  init,
  replayIntegration,
  browserTracingIntegration,
} from '@sentry/remix';

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});

init({
  ...getCommonInitOptions({ SENTRY_DSN: ENV.SENTRY_DSN, APP_ENV: ENV.APP_ENV }),
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }), // +49Kb
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin],
    }), // +124Kb
  ],
  replaysOnErrorSampleRate: 0.5,
  replaysSessionSampleRate: 0,
});
