interface IEnv {
  APP_ENV?: string;
  SENTRY_DSN: string;
}

const release = import.meta.env.VITE_IMAGE_TAG;

const ignoreErrors = [
  /AbortError: This operation was aborted/,
  /No stream found for single fetch decoding/,
  /Load failed/,
  /AbortError: Fetch is aborted/,
  /Unexpected token/,
  /Loading chunk/,
];

export const getCommonInitOptions = ({
  APP_ENV: environment,
  SENTRY_DSN: dsn,
}: IEnv) => {
  const isActiveEnvironment =
    environment === 'production' || environment === 'stage';

  return {
    enabled: isActiveEnvironment,
    dsn,
    environment,
    sampleRate: 1,
    release,
    ignoreErrors,
  };
};
